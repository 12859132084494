import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

const CouponsContainer: React.FC<{ couponsData: any }> = ({ couponsData }) => {

    const {
        removeHtmlTags,
        domain
    } = useContext(HomepageContext)

    return (
        <div className="coupons-container h-[67vh] p-10 m-10 md:space-y-4 shadow-md rounded-lg flex flex-col justify-around items-center">
            <h2 className="ml-4 text-center">{couponsData.post_title}</h2>
            <div className='flex flex-col md:flex-row justify-center items-center space-y-[15px] md:space-y-0 space-x-0 md:space-x-[15px] h-[40%]'>
                <a href={'/' + couponsData.author_url} className='flex flex-row md:flex-col space-x-5 md:space-x-0 justify-center w-[100px] h-[100px]'>
                    <img src={`${domain}/${couponsData.profile_picture}`} alt={couponsData.post_title} className="w-full rounded-full" />
                    <h4 className='text-center md:mt-[10px]'>{couponsData.post_author}</h4>
                </a>
                <div style={{ backgroundColor: "rgb(164,164,71)" }} className='flex items-center justify-center w-full md:w-[65%] h-[25vh] border-2 border-dashed border-black py-4 px-6 text-white'>
                    <p className='text-center'>{removeHtmlTags(couponsData.post_content, 100)}</p>
                </div>
            </div>
            <div className="border-t border-gray-400 w-full"></div>
            <a className="inline-block px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500" href={couponsData.post_filename}>REDEEM COUPON</a>
        </div>
    );

};

export default CouponsContainer