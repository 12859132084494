import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

import he from 'he';

import { responseData, IsMobile } from '../types/Types'

interface HomepageContextType {
    domain: string;
    removeHtmlTags: Function;
    formatDate: Function;
    formatDateEvents: Function;
    formatDateAgo: Function;
    responseData: responseData | null | undefined;
    isMobile: boolean;
};

declare global {
    interface Window { domain: string; homepageData:any }
};

const useIsMobile = (): IsMobile => {
    const [isMobile, setIsMobile] = useState<boolean>(
      window.innerWidth < 768 // set initial value based on screen width
    );
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // update value based on screen width
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return { isMobile };
  };
  

export const HomepageContext = createContext<HomepageContextType>({} as HomepageContextType);

export const HomepageProvider = (props: { children: React.ReactNode }) => {

    let domain = "https://www.360durango.com";

    if (window.domain) {

        domain = window.domain;

    };

    const removeHtmlTags = (str: string, length: number) => {
        str = he.decode(str); // decode HTML entities
        if (str.length > length) {
            return str.replace(/<[^>]*(?:>|&nbsp;)?/gm, '').substring(0, length) + "...";
        } else {
            return str.replace(/<[^>]*(?:>|&nbsp;)?/gm, '');
        }
    }

    function formatDate(timestamp: string): string {
        const date = new Date(timestamp);
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}/${day}/${year}`;
    };

    function formatDateEvents(inputDate: string): string {
        // Split the input string into year, month, and day components
        const year = inputDate.slice(0, 4);
        const month = inputDate.slice(4, 6);
        const day = inputDate.slice(6, 8);

        // Use the Date constructor to create a new Date object
        const date = new Date(`${month}/${day}/${year}`);

        // Use the Intl.DateTimeFormat API to format the date in "MM/DD/YYYY" format
        const formatter = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const formattedDate = formatter.format(date);

        return formattedDate;
    }

    function formatDateAgo(dateString: string): string {
        const date = new Date(dateString);
        const now = new Date();
        const diff = Math.floor((now.getTime() - date.getTime()) / 1000);

        if (diff < 60) {
            return "just now";
        } else if (diff < 3600) {
            const minutes = Math.floor(diff / 60);
            return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
        } else if (diff < 86400) {
            const hours = Math.floor(diff / 3600);
            return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
        } else {
            const days = Math.floor(diff / 86400);
            return `${days} ${days === 1 ? "day" : "days"} ago`;
        }
    }

    const [responseData, setResponseData] = useState<responseData | null>();

    useEffect(() => {

       if (window.homepageData) {
        setResponseData(window.homepageData);
       } else {
        
        axios.get(`${domain}/api/widget/html/get/custom_content_home_api?api=1`)
        .then(response => {
            setResponseData(response.data);
        })
        .catch(error => {
            console.log(error);
        });

       } 




    }, []); 


      const { isMobile } = useIsMobile();

    return (
        <HomepageContext.Provider
            value={{
                domain,
                removeHtmlTags,
                formatDate,
                formatDateEvents,
                formatDateAgo,
                responseData,
                isMobile
            }}
        >
            {
                responseData ? 
                props.children
                : null
            }
        </HomepageContext.Provider>
    );
};
