import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

import { BiDollar } from 'react-icons/bi'
import { RiSuitcaseLine } from 'react-icons/ri'
import { AiOutlineClockCircle } from 'react-icons/ai'

const JobContainer: React.FC<{ jobData: any }> = ({ jobData }) => {

    const {
        formatDateAgo,
        domain
    } = useContext(HomepageContext)

    return (
        <div className="job-container w-full h-[50vh] md:w-[25vw] p-5 md:m-10 md:space-y-2 shadow-xl rounded-lg flex flex-col justify-around items-center">
            <a href={'/' + jobData.author_url} className='flex flex-row items-center'>

                {
                    jobData.profile_picture ?
                        <img src={domain + "/" + jobData.profile_picture} alt={jobData.post_title} style={{ height: '65px', width: '65px' }} className="rounded-full" />
                        : null
                }


                <h2 className="ml-4">{jobData.post_author}</h2>
            </a>
                <div className="border-t border-gray-400 w-1/4 flex flex-col justify-around items-center"></div>
                    <h2>{jobData.post_title}</h2>
                    <div className='flex flex-row items-center'><BiDollar className='mr-2' /><h4>{jobData.post_price && jobData.post_price !== '0.00' ? jobData.post_price : <a href={jobData.post_filename}>Ask For Details</a>}</h4></div>
                    <div className='flex flex-row items-center'><RiSuitcaseLine className='mr-2' /><h4>{jobData.hour_type_id}</h4></div>
                    <div className='flex flex-row items-center'><AiOutlineClockCircle className='mr-2' /><h4>{formatDateAgo(jobData.revision_timestamp)}</h4></div>
                    <div className="border-t border-gray-400 w-full">
                </div>
            <a className="inline-block px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500" href={jobData.post_filename}>VIEW JOB</a>
        </div>
    );

};

export default JobContainer