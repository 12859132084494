import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

const BlogContainer: React.FC<{ blogData: any }> = ({ blogData }) => {

    const {
        removeHtmlTags,
        domain
    } = useContext(HomepageContext)

    return (
        <div className="blog-container w-full h-[67vh] md:w-[25vw] p-5 md:m-10 md:space-y-4 shadow-xl rounded-lg flex flex-col justify-around items-center">
            <h2>ARTICLE</h2>
            <div className="border-t border-gray-400 w-1/4"></div>
            <h2>{blogData.post_title}</h2>
            <img src={`${domain}/${blogData.post_image}`} alt={blogData.post_title} className="w-full h-[40%] object-cover" />
            <p>{removeHtmlTags(blogData.post_content, 150)}</p>
            <div className="border-t border-gray-400 w-full"></div>
            <a className="inline-block px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500" href={blogData.post_filename}>READ MORE</a>
        </div>
    )

};

export default BlogContainer