import React, { useState, useEffect, useContext } from 'react';

import BusinessContainer from './components/BusinessContainer';
import BlogContainer from './components/BlogContainer';
import JobContainer from './components/JobContainer';
import CouponsContainer from './components/CouponsContainer';
import TagSection from './components/TagSection';
import EventContainer from './components/EventContainer';
import AssortedPosts from './components/AssortedPosts';
import FeaturedBlog from './components/FeaturedBlog';
import FeaturedBlog2 from './components/FeaturedBlog2';

import CustomAliceCarousel from './components/CustomAliceCarousel';

import { MainBanner, BannerMap } from './types/Types';
import { HomepageContext, HomepageProvider } from './context/HomepageContext';

import axios from 'axios';

declare global {
  interface Window {
    banners?: BannerMap;
    main_banner?: MainBanner[];
  }
}

function AppContent() {


  const {
    isMobile,
    responseData,
    domain
  } = useContext(HomepageContext);

  const [banners, setBanners] = useState<BannerMap | any>({});
  const [bottomBanner, setBottomBanner] = useState<BannerMap | any>({});

  const fetchHomepageBanners = async () => {
    const res = await axios.get(`${domain}/api/widget/html/get/fetch-banners-homepage`);
    setBanners(res.data.banners);
    setBottomBanner(res.data.main_banner);
    console.log(res.data);
  }

  useEffect(() => {

    fetchHomepageBanners();

  }, [])

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };


  return (
    <div className="App flex justify-center items-center flex-col w-full space-y-[10vh]">

      {responseData && responseData?.blogs && responseData?.blogs.length > 0 &&

        <div className='flex justify-center items-center flex-col w-full bg-white shadow-md mt-[10px]'>

          <div className='flex w-full justify-center items-center'>
          <h2 className="pt-[50px] text-[55px] text-center">EDITOR'S PICKS</h2>
          </div>

          <CustomAliceCarousel
            disableDotsControls={true}
            responsive={responsive}
          >

            {
              responseData?.blogs && responseData?.blogs.length > 0 ?
                responseData?.blogs.map((blog: any, index: number) => {
                  return (
                    <BlogContainer blogData={blog} key={index} />
                  )
                }
                ) : null
            }

          </CustomAliceCarousel>

        </div>

      }

      {responseData && responseData?.blogs[0] && responseData.adventure && Object.keys(responseData.adventure).length > 0 &&
        <TagSection title={"ADVENTURE"}>
          {responseData?.adventure.featuredBlog && responseData?.adventure.featuredBlog.length > 0 ?
            <FeaturedBlog
              blogData={
                responseData?.adventure.featuredBlog[0]
              }
            />
            :
            <FeaturedBlog
              blogData={
                responseData?.blogs[0]
              }
            />
          }

          <AssortedPosts posts={responseData?.adventure} />

          {

            banners && banners[2] && banners[2][0] && banners[2][0].filename ?

              <BusinessContainer
                bannerImage={banners[2][0].filename}
                bannerCopy={banners[2][0].copy}
                bannerUrl={banners[2][0].url}
                displayCopy={banners[2][0].displayCopy}
                buttonCta={banners[2][0].buttonCta}
                profilePicture={banners[2][0].profile_photo} 
                fullName={banners[2][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[2][0].company}
                profile_url={banners[2][0].profile_url} 
              />

              : (
                responseData?.adventure.businesses && responseData?.adventure.businesses.length > 0 ?
                  <BusinessContainer userData={responseData?.adventure.businesses[0]} />
                  :
                  null
              )
          }
        </TagSection>
      }

      {responseData && responseData?.blogs[0] && responseData.local && Object.keys(responseData.local).length > 0 &&
        <TagSection title={"LOCAL"} reverse={true}>


          {

            responseData?.local.featuredBlog && responseData?.local.featuredBlog.length > 1 ?

              <div className={`md:px-[20px] md:space-y-4 divide-y divide-orange-500 w-[100%] max-w-[100%] md:w-[65%] ${!isMobile ? 'max-w-[420px]' : ''} md:mx-[20px] flex flex-col justify-center items-center`}>
                <FeaturedBlog2 blogData={responseData?.local.featuredBlog[0]} />
                <FeaturedBlog2 blogData={responseData?.local.featuredBlog[1]} />
              </div>

              : (responseData?.local.featuredBlog && responseData?.local.featuredBlog.length > 0 ?
                <FeaturedBlog
                  blogData={
                    responseData?.local.featuredBlog[0]
                  }
                />
                :
                <FeaturedBlog
                  blogData={
                    responseData?.blogs[0]
                  }
                />)
          }

          <AssortedPosts posts={responseData?.local} />

          {
            banners && banners[4] && banners[4][0] && banners[4][0].filename ? (
              <BusinessContainer
                bannerImage={banners[4][0].filename}
                bannerCopy={banners[4][0].copy}
                bannerUrl={banners[4][0].url}
                displayCopy={banners[4][0].displayCopy}
                buttonCta={banners[4][0].buttonCta}
                profilePicture={banners[4][0].profile_photo} 
                fullName={banners[4][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[4][0].company} 
                profile_url={banners[4][0].profile_url} 

              />
            ) : (
              <>
                {
                  responseData?.local.businesses && responseData?.local.businesses.length > 0 ?
                    <BusinessContainer userData={responseData?.local.businesses[0]} />
                    : null
                }
              </>
            )
          }


        </TagSection>
      }

      {responseData && responseData?.blogs[0] && responseData.culture && Object.keys(responseData.culture).length > 0 &&
        <TagSection title={"CULTURE"}>


          {responseData?.culture.featuredBlog && responseData?.culture.featuredBlog.length > 0 ?
            <FeaturedBlog
              blogData={
                responseData?.culture.featuredBlog[0]
              }
            />
            :
            <FeaturedBlog
              blogData={
                responseData?.blogs[0]
              }
            />
          }

          <AssortedPosts posts={responseData?.culture} />


          {

            banners && banners[3] && banners[3][0] && banners[3][0].filename ?

              <BusinessContainer
                bannerImage={banners[3][0].filename}
                bannerCopy={banners[3][0].copy}
                bannerUrl={banners[3][0].url}
                displayCopy={banners[3][0].displayCopy}
                buttonCta={banners[3][0].buttonCta}
                profilePicture={banners[3][0].profile_photo} 
                fullName={banners[3][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[3][0].company} 
                profile_url={banners[3][0].profile_url} 

              />

              : (

                responseData?.culture.businesses && responseData?.culture.businesses.length > 0 ?


                  <BusinessContainer userData={responseData?.culture.businesses[0]} /> :

                  null

              )

          }


        </TagSection>
      }

      {
        responseData && responseData?.events && responseData?.events.length > 0 ?

          <div className='flex justify-center items-center flex-col w-full bg-white shadow-md'>

            <h1 className='text-[50px] mb-[50px]'>
              WHAT'S HAPPENING IN DURANGO
            </h1>

            <CustomAliceCarousel
              disableDotsControls={true}
              responsive={responsive}
            >

              {
                responseData?.events.map((event: any, index: number) => {
                  return (
                    <EventContainer eventData={event} key={index} />
                  )
                })
              }

            </CustomAliceCarousel>

          </div>

          : null

      }

      {responseData && responseData?.blogs[0] && responseData.features && Object.keys(responseData.features).length > 0 &&
        <TagSection title={"FEATURES"} >





          {responseData?.features.featuredBlog && responseData?.features.featuredBlog.length > 0 ?
            <FeaturedBlog
              blogData={
                responseData?.features.featuredBlog[0]
              }
            />
            :
            <FeaturedBlog
              blogData={
                responseData?.blogs[0]
              }
            />
          }

          <AssortedPosts posts={responseData?.features} />

          {

            banners && banners[5] && banners[5][0] && banners[5][0].filename ?

              <BusinessContainer
                bannerImage={banners[5][0].filename}
                bannerCopy={banners[5][0].copy}
                bannerUrl={banners[5][0].url}
                displayCopy={banners[5][0].displayCopy}
                buttonCta={banners[5][0].buttonCta}
                profilePicture={banners[5][0].profile_photo} 
                fullName={banners[5][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[5][0].company} 
                profile_url={banners[5][0].profile_url} 

              />

              : (


                responseData?.features.businesses && responseData?.features.businesses.length > 0 ?



                  <div className='flex flex-col'>
                    <BusinessContainer userData={responseData?.culture.businesses[0]} />
                    {
                      responseData?.features.businesses && responseData?.features.businesses.length > 1 ?
                        <BusinessContainer userData={responseData?.culture.businesses[0]} />
                        : null
                    }
                  </div>
                  : null

              )

          }
        </TagSection>
      }

      {responseData && responseData?.blogs[0] && responseData.food && Object.keys(responseData.food).length > 0 &&
        <TagSection title={"FOOD + BEVERAGE"} reverse={true}>

          {responseData?.food.featuredBlog && responseData?.food.featuredBlog.length > 0 ?
            <FeaturedBlog
              blogData={
                responseData?.food.featuredBlog[0]
              }
            />
            :
            <FeaturedBlog
              blogData={
                responseData?.blogs[0]
              }
            />
          }
          <AssortedPosts posts={responseData?.food} />

          {

            banners && banners[6] && banners[6][0] && banners[6][0].filename ?

              <BusinessContainer
                bannerImage={banners[6][0].filename}
                bannerCopy={banners[6][0].copy}
                bannerUrl={banners[6][0].url}
                displayCopy={banners[6][0].displayCopy}
                buttonCta={banners[6][0].buttonCta}
                profilePicture={banners[6][0].profile_photo} 
                fullName={banners[6][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[6][0].company} 
                profile_url={banners[6][0].profile_url} 

              />

              : (

                responseData?.food.businesses && responseData?.food.businesses.length > 0 ?
                  <BusinessContainer userData={responseData?.food.businesses[0]} />
                  : null

              )

          }


        </TagSection>
      }

      {
        responseData && responseData?.jobs && responseData?.jobs.length > 0 ?

          <div className='flex justify-center items-center flex-col w-full bg-white shadow-md'>

            <h1 className='text-[50px] mb-[50px] text-center'>
              JOBS NEAR DURANGO
            </h1>

            <CustomAliceCarousel
              disableDotsControls={true}
              responsive={responsive}
            >

              {
                responseData?.jobs.map((job: any, index: number) => {
                  return (
                    <JobContainer jobData={job} key={index} />
                  )
                })
              }

            </CustomAliceCarousel>

          </div>

          : null

      }

      {responseData && responseData?.blogs[0] && responseData.health && Object.keys(responseData.health).length > 0 &&
        <TagSection title={"HEALTH"}>


          {responseData?.health.featuredBlog && responseData?.health.featuredBlog.length > 0 ?
            <FeaturedBlog
              blogData={
                responseData?.health.featuredBlog[0]
              }
            />
            :
            <FeaturedBlog
              blogData={
                responseData?.blogs[0]
              }
            />
          }

          <AssortedPosts posts={responseData?.health} />

          {

            banners && banners[7] && banners[7][0] && banners[7][0].filename ?

              <BusinessContainer
                bannerImage={banners[7][0].filename}
                bannerCopy={banners[7][0].copy}
                bannerUrl={banners[7][0].url}
                displayCopy={banners[7][0].displayCopy}
                buttonCta={banners[7][0].buttonCta}
                profilePicture={banners[7][0].profile_photo} 
                fullName={banners[7][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[7][0].company} 
                profile_url={banners[7][0].profile_url} 

              />

              : (

                responseData?.health.businesses && responseData?.health.businesses.length > 0 ?
                  <BusinessContainer userData={responseData?.health.businesses[0]} />
                  : null

              )
          }

        </TagSection>
      }

      {responseData && responseData?.blogs[0] && responseData.home && Object.keys(responseData.home).length > 0 &&
        <TagSection title={"HOME"}>

          {responseData?.home.featuredBlog && responseData?.home.featuredBlog.length > 0 ?
            <FeaturedBlog
              blogData={
                responseData?.home.featuredBlog[0]
              }
            />
            :
            <FeaturedBlog
              blogData={
                responseData?.blogs[0]
              }
            />
          }

          <AssortedPosts posts={responseData?.home} />

          {

            banners && banners[1] && banners[1][0] && banners[1][0].filename ?

              <BusinessContainer
                bannerImage={banners[1][0].filename}
                bannerCopy={banners[1][0].copy}
                bannerUrl={banners[1][0].url}
                displayCopy={banners[1][0].displayCopy}
                buttonCta={banners[1][0].buttonCta}
                profilePicture={banners[1][0].profile_photo} 
                fullName={banners[1][0].first_name + ' ' + banners[2][0].last_name} 
                company={banners[1][0].company} 
                profile_url={banners[1][0].profile_url} 

              />

              : (
                responseData?.home.businesses && responseData?.home.businesses.length > 0 ?
                  <BusinessContainer userData={responseData?.home.businesses[0]} />
                  :
                  null
              )
          }
        </TagSection>
      }

      {
        responseData && responseData?.coupons && responseData?.coupons.length > 0 ?

          <div className='flex justify-center items-center flex-col w-full bg-white shadow-md'>

            <h1 className='nomargin sm-text-center streaming-title'>
              LOCAL DEALS
            </h1>

            <CustomAliceCarousel
              disableDotsControls={true}
              responsive={responsive}
            >

              {
                responseData?.coupons.map((coupon: any, index: number) => {
                  return (
                    <CouponsContainer couponsData={coupon} key={index} />
                  )
                })
              }

            </CustomAliceCarousel>

          </div>

          : null

      }

      {
        bottomBanner && bottomBanner[0] && bottomBanner[0].filename !== '' ?

          (
            bottomBanner[0].displayCopy === "1" ?
              <div className={'mt-[100px] mb-[100px] flex w-[1500px] h-[300px] rounded-xl relative'}>
                <div className='bg-[#A6A54F] w-[150px] h-[25px] rounded-bl-lg rounded-tr-lg text-white absolute top-0 right-0 flex justify-center items-center'>Advertisement</div>
                <img className="rounded-xl" src={domain + bottomBanner[0].filename} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                <div className='flex absolute justify-center items-center top-0 left-0 w-full h-full'>

                  <div className='relative flex flex-col justify-center items-center  w-[35vw] h-[75%]  rounded-xl'>
                    <div className='absolute w-full h-full rounded-xl z-1 bg-[#A6A54F] opacity-75'></div>
                    <div className='  z-10 flex flex-col justify-center items-center space-y-5'>
                      <p className='text-white'>{bottomBanner[0].copy}</p>
                      <a href={bottomBanner[0].url ? bottomBanner[0].url : ''} target="_blank" className='z-10 flex content-center items-center inline-block mx-4 px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500 w-[160px] flex justify-center'>{bottomBanner[0].buttonCta}</a>
                    </div>
                  </div>

                </div>
              </div>
              :
              <a className={'scale-[0.9] mt-[100px] mb-[100px] flex w-[1500px] h-[300px] rounded-xl relative'} href={bottomBanner[0].url ? bottomBanner[0].url : ''}>
                <div className='bg-[#A6A54F] w-[150px] h-[25px] rounded-bl-lg rounded-tr-lg text-white absolute top-0 right-0 flex justify-center items-center'>Advertisement</div>
                <img className="rounded-xl" src={domain + bottomBanner[0].filename} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </a>
          )
          :
          null
      }

      <div></div>

    </div>
  )

}

function App() {

  return (
    <HomepageProvider>
      <AppContent />
    </HomepageProvider>
  );
}

export default App;
