import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

const FeaturedBlog: React.FC<any> = ({ blogData }) => {

    const {
        formatDate,
        removeHtmlTags,
        isMobile,
        domain
    } = useContext(HomepageContext)

    return (
        <a href={"/" + blogData.post_filename} className={`featured-blog w-full md:w-[50vw] ${!isMobile ? 'max-w-[500px]' : ''}`}>
            <h1 className="w-full mb-2 text-[20px] font-bold">{blogData.post_title}</h1>
            <p className='mb-4'>{formatDate(blogData.revision_timestamp)}</p>
            <img
                className='mb-2'
                src={domain + "/" + blogData.post_image}
                alt={blogData.post_title}
            />
            <p className='w-full'>{removeHtmlTags(blogData.post_content, 100)}</p>
        </a>
    );
};

export default FeaturedBlog