import { useContext } from "react";
import { postType } from "../types/Types";
import { AiOutlineCalendar } from 'react-icons/ai';
import { HomepageContext } from "../context/HomepageContext";

const AssortedPosts: React.FC<any> = ({ posts }) => {

    const {
        removeHtmlTags,
        formatDate
    } = useContext(HomepageContext) 
    
    const postArray: any[] = [];

    const itemClasses: string = "p-5 flex flex-col justify-center items-center";

    if (posts.blogs) {

        posts.blogs.forEach((post: postType) => {
            postArray.push(
                <div>
                    <a className={itemClasses} href={post.post_filename}>
                        <h3 className='text-center'>{post.post_title}</h3>
                        <p className="font-bold text-center">{removeHtmlTags(post.post_content, 20)}</p>
                        <p>{formatDate(post.revision_timestamp)}</p>
                        <p className='text-[#A4A447]'>Article</p>
                    </a>
                </div>
            );
        });

    };

    if (posts.coupons) {

        posts.coupons.forEach((post: postType) => {
            postArray.push(
                <div>
                    <a className={itemClasses} href={post.post_filename}>
                        <h3 className='text-center'>{post.post_title}</h3>
                        <h4 className="font-bold text-center">{post.post_author}</h4>
                        <p className='text-[#A4A447]'>Coupon</p>
                    </a>
                </div>

            );
        });

    };

    if (posts.events) {

        posts.events.forEach((post: postType) => {
            postArray.push(
                <div>
                    <a className={itemClasses} href={post.post_filename}>
                        <h3 className="text-center">{post.post_title}</h3>
                        <div className='flex flex-row items-center'>
                            <AiOutlineCalendar className='mr-1 mt-0.5' />
                            Date: {formatDate(post.revision_timestamp)}
                        </div>
                        <p className='text-[#A4A447]'>Event</p>
                    </a>
                </div>
            );
        });
    };

    if (posts.jobs) {

        posts.jobs.forEach((post: postType) => {
            postArray.push(
                <div>
                    <a className={itemClasses} href={post.post_filename}>
                        <h3 className='text-center'>{post.post_title}</h3>
                        <p className='text-center'>{post.post_author}</p>
                        <p className='text-[#A4A447]'>Job</p>
                    </a>
                </div>
            );
        });
    };

    if (posts.features) {

        posts.jobs.forEach((post: postType) => {
            postArray.push(
                <div className={itemClasses}>
                    <h3 className='text-center'>{post.post_title}</h3>
                    <p className='text-center'>{post.post_author}</p>
                    <p className='text-[#A4A447]'>Features</p>
                </div>
            );
        });
    };

    if (postArray.length > 0) {

        // show a max of 6, but randomize the order too

        postArray.sort(() => Math.random() - 0.5);

        postArray.length = 6;

        return (
            <div className='assorted-posts w-full md:w-[20vw] mx-5 divide-y divide-[#A4A447]'>
                {postArray}
            </div>
        )
    } else return null
};

export default AssortedPosts