import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

import React from 'react';

type TagSectionProps = {
    reverse?: boolean;
    title: string;
    children: React.ReactNode;
};

const TagSection: React.FC<TagSectionProps> = ({ children, reverse, title }) => {

    const {
        isMobile
    } = useContext(HomepageContext)

    const titleClass: string = "text-[50px] mb-[50px]";

    // Reverse the children array if reverse prop is true
    const reversedChildren = reverse ? React.Children.toArray(children).reverse() : children;


    return (
        <div className='tag-section w-[90vw] p-10 flex flex-col content-center items-center'>
            <h1 className={titleClass}>{title}</h1>
            <div className={`flex flex-col md:flex-row justify-center items-center ${isMobile ? 'space-y-10' : 'space-x-10'}`}>
                {
                
                reversedChildren                
                
                }
            </div>
        </div>
    );
};

export default TagSection