import React, { useContext } from 'react';
import { HomepageContext } from '../context/HomepageContext';

const BusinessContainer: React.FC<{ userData?: any, 
    bannerImage?:string, 
    bannerUrl?:string, 
    bannerCopy?:string, 
    displayCopy?:string, 
    buttonCta?:string,
    profilePicture?:string,
    fullName?:string,
    company?:string, 
    profile_url?:string
}> = ({ userData, bannerImage, bannerUrl, bannerCopy, displayCopy, buttonCta, profilePicture, fullName, company, profile_url }) => {

const {domain} = useContext(HomepageContext)

    if (bannerImage) {

        if (displayCopy === "1") {

        return (
            <div style={{ margin: '0!important', boxShadow: 'rgba(0, 0, 0, 0.35) 2px 3px 4px' }} className="min-h-[450px] p-10 md:space-y-4 shadow-md rounded-lg flex flex-col justify-center items-center w-full md:min-w-[280px] md:max-w-[30%] md:w-[20vw] relative">
                <div className="absolute top-0 right-0 border-t border-r rounded-tr-lg bg-[#A4A447] text-white font-bold text-sm px-2 py-1 rounded-bl-md">
                    Advertisement
                </div>

                <a href={profile_url} className='flex flex-row w-full justify-start items-center space-x-1 pb-2'>
                    <img
                    src={domain + "/" + profilePicture}
                    className='!h-[90px] !w-[70px] object-contain'
                    ></img>
                    <div className='flex flex-col'>
                        <h1 className='ml-[5px] text-[16px] font-bold'>{company && company !== "" ? company : fullName}</h1>
                    </div>
                </a>

                <div className='w-[280px] h-[150px]'>
                    <img
                        className='object-cover !h-full !w-full'
                        src={domain + bannerImage}
                        alt={""}
                    ></img>
                </div>

                <p className='text-center'>

                    {bannerCopy}

                </p>
                <a className="flex content-center items-center inline-block mx-4 px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500"
                href={bannerUrl}>{buttonCta}</a>
            </div>
        )

        } else {

            return (
                <div style={{ margin: '0!important', boxShadow: 'rgba(0, 0, 0, 0.35) 2px 3px 4px' }} className="business-container-1 w-full h-[60vh] md:w-[20vw] my-5 shadow-xl rounded-lg flex flex-col justify-center items-center relative">
                    <div className="absolute top-0 right-0 border-t border-r rounded-tr-lg bg-[#A4A447] text-white font-bold text-sm px-2 py-1 rounded-bl-md">
                        Advertisement
                    </div>
        
                    <a className="!w-full !h-full !m-[0px] !p-[0px]" href={bannerUrl}>    
                        <img
                            className='!w-full !h-full object-cover rounded-[5px]'
                            src={domain + bannerImage}
                        ></img>
                    </a>
    
                </div>
            )

        }

    } else {

        return (
            <div style={{ margin: '30px!important' }} className="business-container-2 w-full h-[60vh] md:w-[23vw] mx-10 p-10 shadow-xl rounded-lg flex flex-col justify-around items-center md:space-y-4 relative">
    
                <div className="absolute top-0 right-0 border-t border-r rounded-tr-lg bg-[#A4A447] text-white font-bold text-sm px-2 py-1 rounded-bl-md">
                    Advertisement
                </div>
    
                <h2 className="ml-4">{userData.company ? userData.company : userData.first_name + " " + userData.last_name}</h2>
                <img src={`${domain}/${userData.profile_picture}`} alt={userData.company} className="w-50 h-50 rounded-full" />
                <div className="border-t border-gray-400 w-full"></div>
                <div className='flex flex-row'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                        </div>
                    </div>
                    {
                        userData.filename ?
                            <a className="flex content-center items-center inline-block mx-4 px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500"
                                href={'/' + userData.filename}>VIEW BUSINESS</a>
                            : null
                    }
                </div>
            </div>
        );

    }

};

export default BusinessContainer;