import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'

const EventContainer: React.FC<{ eventData: any }> = ({ eventData }) => {

    const {
        removeHtmlTags,
        formatDateEvents,
        domain
    } = useContext(HomepageContext)

    return (
      <div className="event-container text-[12px] h-[60vh] min-h-[420px] p-5 m-10 md:space-y-4 shadow-md rounded-lg flex flex-col justify-center items-evenly">
        <h2 className="ml-4 !text-[25px]">{eventData.post_title}</h2>
        <a className="w-full !h-[200px]" href={eventData.post_filename}>
          {
            eventData.post_image && eventData.post_image !== "" ?
              <img src={domain + "/" + eventData.post_image} alt={eventData.post_title} className="!w-full !h-full min-h-full min-w-full object-cover" />
              : <img src={domain + "/" + eventData.profile_picture} alt={eventData.post_title} className="!w-full !h-full min-h-full min-w-full object-cover" />
          }
        </a>
        <p className="min-h-[30px]">{removeHtmlTags(eventData.post_content, 75)}</p>
        <div className="border-t border-gray-400 w-full"></div>
        <div className='flex flex-row'>
          <div className='flex flex-col w-full justify-around'>
            <div className='flex flex-row'>
              <AiOutlineCalendar className='mr-2' /><p>{formatDateEvents(eventData.post_start_date)}</p>
            </div>
            {eventData && eventData.start_time && eventData.start_time !== 0 && eventData.start_time !== "0" ?
              <div className='flex flex-row'>
                <AiOutlineClockCircle className='mr-2' /><p>{eventData.start_time +
                  (eventData.end_time ? " - " + eventData.end_time : "")
                }</p>
              </div>
              : ""
            }
          </div>
          <a className="flex content-center items-center inline-block mx-4 px-4 py-2 bg-orange-400 text-white rounded-lg text-center font-bold cursor-pointer hover:bg-orange-500 w-[160px] flex justify-center" href={eventData.post_filename}>MORE INFO</a>
        </div>
      </div>
    );

  };

export default EventContainer