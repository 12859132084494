import React, { useRef } from "react";
import AliceCarousel, { Props as AliceCarouselProps } from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface CustomAliceCarouselProps extends AliceCarouselProps {
  children: React.ReactNode;
}

const CustomAliceCarousel: React.FC<CustomAliceCarouselProps> = (props) => {
  const { children, ...rest } = props;

  const carouselRef = useRef<AliceCarousel>(null);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 3 },
  };  

  let items;
  if (Array.isArray(children)) {
    items = children.length <= 3 ? [1] : rest.items;
  } else {
    items = rest.items;
  }

  return (
    <div className="custom-carousel w-full flex flex-row justify-center">
      <button
        className="w-[20px] custom-carousel-arrow custom-carousel-arrow-left"
        onClick={handlePrevClick}
      >
        <IoIosArrowBack />
      </button>

      <div className="carousel-wrapper" style={{ width: "90%" }}>
        <AliceCarousel
          {...rest}
          items={items}
          responsive={responsive}
          controlsStrategy="responsive"
          ref={carouselRef}
          disableButtonsControls
        >
          {children}
        </AliceCarousel>
      </div>

      <button
        className="w-[20px] custom-carousel-arrow custom-carousel-arrow-right"
        onClick={handleNextClick}
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
};

export default CustomAliceCarousel;
