import { useContext } from "react";
import { HomepageContext } from "../context/HomepageContext";

const FeaturedBlog2: React.FC<any> = ({ blogData }) => {

    const {
        formatDate,
        removeHtmlTags,
        isMobile,
        domain
    } = useContext(HomepageContext)

    return (
        <div className="featured-blog-2 w-full mx-5 py-4 flex flex-col">

            <a href={"/" + blogData.post_filename} className="flex flex-row h-[160px]">

                <div className="mr-5">
                    <h3 className={`${!isMobile ? 'w-[160px]' : ''} mb-4 text-[20px] font-bold`}>{blogData.post_title}</h3>
                    <h4>{formatDate(blogData.revision_timestamp)}</h4>
                </div>
                <div className="w-[20vw] h-[15vh] flex justify-center">
                    <img
                        className='object-cover w-full h-full'
                        src={domain + "/" + blogData.post_image}
                        alt={blogData.post_title}
                        style={{ width: '100%!important' }}
                    />
                </div>

            </a>

            <p>{removeHtmlTags(blogData.post_content, 100)}</p>

        </div>
    );
};

export default FeaturedBlog2